import { ErrorBoundaryContent } from "components/error/PageErrorBoundary";
import Layout from "components/Layout";

/*
  Dedicated Custom Error page for Auth0 usage.

  To test locally, you need to use ngrok, so this URL will work. https://alternativ-dev.ngrok.io/error

  https://auth0.com/docs/customize/universal-login-pages/custom-error-pages#redirect-users-to-a-custom-error-page-using-the-dashboard
*/
const ErrorPage = () => (
  <Layout pageIdentifier="500">
    <ErrorBoundaryContent showLogout />
  </Layout>
);

export default ErrorPage;
